import * as React from 'react'
import Nav from "../components/nav";
import Footer from "../components/footer";
import Wellness1 from "../images/wellness-1.jpg"
import Wellness2 from "../images/wellness-2.jpg"
import Wellness3 from "../images/wellnes-3.jpg"
import Wellness4 from "../images/wellness-4.jpg"
import SocialMedia from "../components/social-media";
import Hero from "../components/hero";
import {Helmet} from "react-helmet";


function WellnessAndRecovery(){
    return(
        <div>
            <Helmet>
                <title>Kinetic Impact | Wellness and Recovery</title>
            </Helmet>
            <Nav/>
            <Hero href={"https://kineticimpact.janeapp.com/#/1st-timers"} header={"Want to recover and feel good after a hard workout?"}>
            <div className={"flex flex-col justify center max-w-5xl mx-auto px-8 pt-8 text-lg bg-white rounded-xl"}>
                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}>If you have been hitting it hard, you have earned your recovery. Sometimes eating healthy, staying hydrated and getting good sleep doesn’t feel like it is enough. We are here to help! There is nothing wrong with treating yourself so you feel good.</p>
                <im className={"max-h-[500px] object-contain"} src={Wellness1} alt={'review'}/>
                <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Let's Get Started!</button></a>

                <h2 className={"text-3xl text-center py-14"}>Body feeling a little stiff after a long day of work?</h2>
                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}>It is a little hard to be as productive as you can when your body doesn’t feel like it is firing at all cylinders. If a little tightness is getting in your way, we can help! This is your time where you can shut work off and leave our doors feeling better than when you walked in. We are here for you!</p>
                <img className={"max-h-[500px] object-contain"} src={Wellness2} alt={'review'}/>
                <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Let's Get Started!</button></a>

                <h2 className={"text-3xl text-center py-14"}>Loosen up for that long trip</h2>
                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}>There is nothing worse than feeling tight and tense on a long trip. We help people like you feel looser and less tense through active release (deep massage with movement), cupping with movement, chiropractic adjustments and more.</p>
                <img className={"max-h-[500px] object-contain"} src={Wellness3} alt={'review'}/>
                <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Let's Get Started!</button></a>

                <h2 className={"text-3xl text-center py-14"}>Feel great before a workout or performance</h2>
                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}>Like Deion Sanders said “If you feel good, you play good.” If you have taken the measures to master the basics like food, water and sleep then you have earned all the benefits that things like massage, cupping, compression boots, chiropractic adjustments and percussion devices have to offer. This is where we can help!</p>
                <img className={"max-h-[500px] object-contain"} src={Wellness4} alt={'review'}/>
                <a className={"mx-auto pb-10"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Let's Get Started!</button></a>




            </div>
            </Hero>
            <SocialMedia/>


            <Footer/>
        </div>
    )
}

export default WellnessAndRecovery